<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{
        requestId ? t("headings.create_task_for") : t("headings.create_task")
      }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="createTask"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
import { getISODate } from "../utils/dateUtils";

export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const requestId = ref(route.query?.requestId);
    const projectId = ref(route.params?.projectId);

    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const partners = computed(() => store.state.partner.partners);
    const project = computed(() => store.state.project.project);
    const maintenancePersonnel = computed(
      () => store.state.user.maintenancePersonnel
    );
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.partner.waiting) return true;
      if (store.state.project.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const statuses = ref([
      { name: t("status.todo"), value: "todo" },
      { name: t("status.doing"), value: "doing" },
      { name: t("status.done"), value: "done" },
    ]);

    const partner = ref(null);

    const formElements = ref();

    const setFormElements = () => {
      if (projectId.value) {
        formElements.value = [
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "name",
            label: "name",
            rules: ["isRequired"],
            type: "text",
            value: "",
            variable: "name",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "datepicker",
            id: "startDate",
            label: "start_date",
            rules: ["isRequired"],
            textVariant: "white",
            value: getISODate(new Date()),
            variable: "startDate",
            variant: "indigo",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "datepicker",
            id: "endDate",
            label: "end_date",
            rules: ["isRequired"],
            textVariant: "white",
            value: getISODate(new Date()),
            variable: "endDate",
            variant: "indigo",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "status",
            label: "status",
            optionLabel: ["name"],
            options: statuses,
            rules: ["isRequired"],
            value: "",
            variable: "status",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "partner",
            label: "partner_contact",
            noneOption: true,
            optionLabel: ["name"],
            options: partners.value,
            rules: [],
            value: "",
            variable: "partner",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "maintenancePerson",
            label: "maintenance_person",
            optionLabel: ["firstName", "lastName"],
            options: maintenancePersonnel.value,
            rules: [],
            value: "",
            variable: "maintenancePersonId",
          },
          {
            class: "col-span-12 text-right",
            element: "button",
            id: "submit-button",
            label: "save",
            textVariant: company.value.primaryText,
            type: "submit",
            variant: company.value.primary,
          },
        ];
      } else {
        formElements.value = [
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "name",
            label: "name",
            rules: ["isRequired"],
            type: "text",
            value: "",
            variable: "name",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "datepicker",
            id: "startDate",
            label: "start_date",
            rules: ["isRequired"],
            textVariant: "white",
            value: getISODate(new Date()),
            variable: "startDate",
            variant: "indigo",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "datepicker",
            id: "endDate",
            label: "end_date",
            rules: ["isRequired"],
            textVariant: "white",
            value: getISODate(new Date()),
            variable: "endDate",
            variant: "indigo",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "status",
            label: "status",
            optionLabel: ["name"],
            options: statuses,
            rules: ["isRequired"],
            value: "",
            variable: "status",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "estate",
            label: "estate_name",
            optionLabel: ["name"],
            options: estates.value,
            rules: ["isRequired"],
            value: null,
            variable: "estateId",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "partner",
            label: "partner_contact",
            noneOption: true,
            optionLabel: ["name"],
            options: partners.value,
            rules: [],
            value: "",
            variable: "partner",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "maintenancePerson",
            label: "maintenance_person",
            optionLabel: ["firstName", "lastName"],
            options: maintenancePersonnel.value,
            rules: [],
            value: "",
            variable: "maintenancePersonId",
          },
          {
            class: "col-span-12 text-right",
            element: "button",
            id: "submit-button",
            label: "save",
            textVariant: company.value.primaryText,
            type: "submit",
            variant: company.value.primary,
          },
        ];
      }
    };

    const createTask = (formData) => {
      const data = {
        companyId: user.value.companyId,
        estateId: formData.estateId || project.value.estateId || null,
        requestId: requestId.value ? requestId.value : null,
        phaseName: route.params.phaseName ? route.params.phaseName : "",
        projectId: route.params.projectId ? route.params.projectId : "",
        createdAt: new Date().toISOString().split("T")[0],
        name: formData.name,
        startDate: formData.startDate,
        endDate: formData.endDate,
        status: formData.status,
        maintenancePersonId: formData.maintenancePersonId,
      };
      if (route.params.phaseName) {
        store.dispatch("task/createProjectTask", {
          data,
          projectId: data.projectId,
          phaseName: route.params.phaseName,
        });
      } else {
        store.dispatch("task/createTask", data);
      }
    };

    onMounted(() => {
      if (!estates.value.length) {
        store.dispatch("estate/getEstates", company.value.id);
      }
      if (route.params.projectId) {
        store.dispatch("project/getProject", route.params.projectId);
      }
      store.dispatch("partner/getPartners");
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    watch(
      () => partner.value,
      (partner) => {
        store.dispatch("user/getMaintenancePersonnel", partner.id);
      }
    );

    watch(
      () => partners.value,
      (partners) => {
        if (partners.length) {
          setFormElements();
        }
      }
    );

    watch(
      () => maintenancePersonnel.value,
      (maintenancePersonnel) => {
        if (maintenancePersonnel.length) {
          setFormElements();
        }
      }
    );

    return {
      createTask,
      formElements,
      requestId,
      project,
      statuses,
      t,
    };
  },
};
</script>
